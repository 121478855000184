import React from "react"

interface Props {
  children: JSX.Element[] | JSX.Element
}

export default ({ children }: Props): JSX.Element => (
  <ul className="list-disc ml-10 text-lg space-y-2 mt-3 text-gray-500">
    {children}
  </ul>
)

import React from "react"
import { TypoTag } from "./TypoTag"

export interface BaseTextElementProps {
  tag: TypoTag
  children: string | JSX.Element
  additionalClassNames?: string
}

interface Props extends BaseTextElementProps {
  baseClassNames: string
}

export default ({
  tag,
  children,
  baseClassNames,
  additionalClassNames,
}: Props): JSX.Element => {
  const classes = [baseClassNames]

  if (additionalClassNames) classes.push(additionalClassNames)

  return React.createElement(
    tag,
    {
      className: classes.join(" "),
    },
    children
  )
}

import React from "react"
import BaseTextElement, { BaseTextElementProps } from "./BaseTextElement"
import { TypoTag } from "./TypoTag"

interface Props extends BaseTextElementProps {
  tag: TypoTag
  children: string | JSX.Element
  textColor?: "text-white"
  additionalClassNames?: string
}

export default ({
  tag,
  children,
  textColor,
  additionalClassNames,
}: Props): JSX.Element => {
  const defaultTextColor = "text-gray-900"

  const baseClasses = [
    `mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl`,
    textColor || defaultTextColor,
  ]

  return (
    <BaseTextElement
      baseClassNames={baseClasses.join(" ")}
      additionalClassNames={additionalClassNames}
      tag={tag}
    >
      {children}
    </BaseTextElement>
  )
}

import React from "react"

interface Props {
  children: string | JSX.Element | (JSX.Element | string)[]
  textColor?: "text-white"
}

export default ({ children, textColor }: Props): JSX.Element => {
  const defaultTextColor = "text-gray-500"

  const baseClasses = [
    `prose prose-indigo mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 text-lg`,
    textColor || defaultTextColor,
  ]

  return (
    <div className={baseClasses.join(" ")}>
      <p className="mt-3">{children}</p>
    </div>
  )
}

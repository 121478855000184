import { Link } from "gatsby"
import React from "react"
import BasicList from "../../components/BasicList"
import BlogTitle from "../../components/BlogTitle"
import Container from "../../components/Container"
import Hero from "../../components/Hero"
import HeroLead from "../../components/HeroLead"
import HeroTitle from "../../components/HeroTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SubTitle from "../../components/SubTitle"
import Text from "../../components/Text"
import links from "../../content/links"
import metaImg from "../../images/how-release-app.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Miten julkaista mobiiliapplikaatio?"
      description="Lue blogistamme, mitä kannattaa ottaa huomioon julkaistaessa mobiilisovellusta. Käsittelemme mm. kohderyhmiä, testausta ja kauppa-alustoja mobiilisovelluksille."
      pageImage={metaImg}
      pageUrl={links.blogSites.mobileAppRelease}
    />

    <section id="hero">
      <Hero hideContact>
        <HeroTitle>
          <h1>
            <span className="block">Miten julkaista</span>
            <span className="block text-indigo-400">mobiiliapplikaatio?</span>
          </h1>
        </HeroTitle>
        <HeroLead>Kirjoittanut: Essi Kivivuori</HeroLead>
      </Hero>
    </section>

    <section>
      <Container>
        <Text>
          Kokosimme yhteen muutamia vinkkejä, joita voit ottaa huomioon
          julkaistaessa mobiiliapplikaatiota.
        </Text>

        <BlogTitle>Kohderyhmän kartoitus</BlogTitle>
        <Text>
          Kannattaa luoda kohderyhmä, sillä sen avulla pystyt määrittämään
          ensinnäkin mille alustalle haluat sovelluksesi julkaistavan. Mitä
          sukupuolta käyttäjät edustavat, ovatko he lapsia, teinejä, aikuisia
          vai vanhuksia? Jos sinulla on jo valmiiksi asiakkaita, kannattaa tehdä
          markkinatutkimus ja saada vastaukset suoraan asiakkailtasi. Jos
          yritykselläsi ei ole aikaisempia asiakkaita – voit vastata kysymyksiin
          itse, miettimällä millainen on tuleva asiakkaasi ja minkälaisia
          sovelluksia hän käyttää.
        </Text>
        <BlogTitle tag="h3">Markkinatutkimus</BlogTitle>
        <Text>
          Markkinatutkimuksen tekeminen teknisesti ei ole vaikeaa internetin
          välityksellä. Sillä siihen löytyy internetistä paljon helppoja ja
          ilmaisia työkaluja – esimerkiksi Typeform{" "}
          <Link className="text-indigo-500" to="https://www.typeform.com/">
            https://www.typeform.com/
          </Link>
          tai Google Forms{" "}
          <Link
            className="text-indigo-500"
            to="https://www.google.com/forms/about/"
          >
            https://www.google.com/forms/about/
          </Link>
          . Näiden työkalujen avulla pystyt tekemään kyselyjä ja analysoimaan
          saamasi vastaukset nopeasti ja helposti.
        </Text>

        <Text>
          Mikäli yrityksellä on jo olemassa olevia asiakkaita, on
          markkinatutkimuksen tekeminen helpompaa kuin yrityksille joka
          valmistelee mobiilisovelluksen julkaisua uuden yrityksen
          lähtökohdista.
        </Text>
        <Text>
          Mikäli projektissa on suurempi budjetti, voidaan markkinatutkimus
          tuottaa yhteistyössä markkinatutkimuksia tekevien yritysten kanssa.
          Pienemmissä budjeteissa on miettittävä voidaanko tutkimus
          mahdollisesti tehdä pientä palkkiota vastaan, joka helpottaa hieman
          tutkimuksen tekemistä? Voidaanko tutkimus tehdä yhteistyössä toisen
          yrityksen kanssa tai viimeisenä vaihtoehtona tutkimus voidaan
          suorittaa jakamalla tutkimus henkilökohtaisille tuttavilla ketkä
          sopivat kohderyhmään.
        </Text>
        <Text>
          Mitä sitten markkinatutkimuksessa kannattaa kysyä ihmisiltä? Aiheet
          riippuvat hieman projektista, mutta usein kysytään seuraavista
          aiheista:
        </Text>

        <BasicList>
          <li>Harrastuksista</li>
          <li>Internetkäyttäytymisestä</li>
          <li>Ostoskäyttäytymisestä</li>
          <li>Koulutuksesta</li>
          <li>Työstä</li>
          <li>Väestötiedoista</li>
          <li>Talouden vuosittaiset tulot</li>
        </BasicList>

        <Text>
          Saadessasi niihin vastaukset pystyt helpommin suunnittelemaan
          sovelluksen teknisiä ratkaisuita ja lopulta se tulee auttamaan sinua
          mobiiliapplikaation markkinoinnissa.
        </Text>

        <BlogTitle tag="h3">Kohdehenkilö</BlogTitle>
        <Text>
          Kohdehenkilö on kuvitteellinen henkilö, joka määräytyy tekemästäsi
          markkinatutkimuksesta. Kohdehenkilöitä voi olla monia tai vain yksi.
          Mitä enemmän sinulla on tutkimustulosta kohderyhmän kartoittamisesta,
          sitä paremmin pystyt hahmottamaan kohdehenkilön.
        </Text>

        <SubTitle tag="h4" additionalClassNames="my-5">
          Miten luodaan kohdehenkilö?
        </SubTitle>
        <Text>
          Sen jälkeen kun olet saanut markkinatutkimuksen valmiiksi ja sinulla
          on haluttuihin kysymyksiin vastaukset on aika luoda kohdehenkilö. Kun
          sinulla on vastaukset edessäsi, käyt ne yksitellen läpi ja jokaisesta
          kysymyksestä mihin olet saanut vastauksen otat keskiarvon.
        </Text>

        <BasicList>
          <li>Nimi: Matti Meikäläinen</li>
          <li>Väestötiedot & Tausta</li>
          <BasicList>
            <li>Mies, 50-vuotias</li>
            <li>Naimisissa</li>
            <li>2 lasta (13 ja 15)</li>
            <li>Talouden tulot: 120 000/vuosi</li>
            <li>Omistaa yrityksen / työskentelee korkeassa asemassa</li>
            <li>Asuu pääkaupunkiseudulla</li>
            <li>Vaimo työskentelee hoitoalalla</li>
          </BasicList>
          <li>Harrastukset</li>
          <BasicList>
            <li>Kuntoilu</li>
            <li>Ulkona syöminen</li>
            <li>Perheen ja ystävien kanssa ajan viettäminen</li>
            <li>Lukeminen</li>
            <li>Internetin tutkiminen</li>
          </BasicList>
          <li>Tarpeet</li>
          <BasicList>
            <li>Ei halua monimutkaisia sovelluksia</li>
            <li>Lataa ainoastaan hyödyllisiä ja aikaa säästäviä sovelluksia</li>
            <li>Sosiaalisen median tutkiminen</li>
            <li>Työhön liittyvien artikkelien lukeminen</li>
          </BasicList>
          <li>Tavoitteet</li>
          <BasicList>
            <li>Saada tasapaino työn ja vapaa-ajan välille</li>
            <li>Taloudellinen riippumattomuus</li>
            <li>Saada yritykselle näkyvyyttä ja kasvamaan</li>
          </BasicList>
          <li>Huolet</li>
          <BasicList>
            <li>Lapset murrosiässä</li>
            <li>Teknologian kehitys / kehityksen perässä pysyminen</li>
            <li>Ei ole tarpeeksi vapaa-aikaa</li>
            <li>Asuntolaina & autolaina</li>
          </BasicList>
        </BasicList>

        <BlogTitle>Testaus</BlogTitle>
        <Text>
          Kun sovelluksesi on valmis tai melkein valmis – on aika testaukselle.
          Testaus on tuotteesi kannalta todella tärkeää, sillä bugisesta
          tuotteesta ei ole kuin harmia, tosin täysin niiden välttäminen lienee
          myös mahdotonta. Jos julkaiset tuotteen ilman testausta kauppoihin ja
          se ei toimikkaan haluamallasi tavalla, voit saada paljon negatiivista
          palautetta tuotteestasi, jolloin menetät käyttäjiä.
        </Text>
        <Text>
          Testauksessa kannattaa ottaa huomioon, että tuntemattoman osapuolen
          näkemys on aina parempi kuin niin sanotusti lähimmäisen mielipide
          asiaan, kaunistellusta palautteesta ei ole tässä kohtaa hyötyä.
          Tarvitset kaunistelematta olevan ja suoran palautteen tuotteestasi,
          siten sinulla on eniten hyötyä siitä.
        </Text>
        <Text>
          Sovellusta voidaan testata sovellusten testaukseen erikoistuneiden
          yritysten kanssa. Sovellusta voidaan testata henkilökunnan kanssa,
          ystävien kanssa tai kenen tahansa kanssa kenellä on sopiva laite ja
          halu antaa hieman aikaa sovellukselle. Joissain tapauksissa on
          mahdollista löytää testaajia yhteisön kautta eli fanien, sosiaalisen
          median yleisön tai valmiista asiakaskunnasta.
        </Text>

        <BlogTitle>Kauppa-alustat</BlogTitle>
        <Text>
          Huolella suunniteltu kauppasivu on tärkeä niin Google Play kuin Apple
          App Store:ssa. Tutkimusten mukaan ihmiset käyttävät vain 10 sekuntia
          tutkiessaan sovellusta ja tekevät siinä ajassa päätöksen, lataavatko
          he sovelluksen vai eivät. Kauppasivulle lisätään kuva sovelluksen
          launcher-ikonista, kuvakaappauksia sovelluksesta sekä kuvaus
          sovelluksesta.
        </Text>
        <Text>
          Ensivaikutelman lisäksi, on huomioitava avainsanat sovellusta
          lisättäessä. Niitä on tutkittava ja päivitettävä, sillä sinun on
          mahdollista lisätä vain rajattu määrä avainsanoja. Avainsanojen
          etsiminen ja kartoittaminen on erittäin tärkeää. Oikeilla
          avainsanoilla voidaan saavuttaa huomattavasti suuremmat lataukset. On
          kuitenkin syytä huomata, että nykypäivänä avainsanojen ja algoritmien
          kanssa trendit liikkuvat nopealla tahdilla, joten ei riitä että lisäät
          avainsanat sovelluskauppaan applikaatiota julkaistaessa kerran, vaan
          sitä on päivitettävä ja seurattava aktiivisesti.
        </Text>

        <BlogTitle tag="h3">Apple App store</BlogTitle>
        <Text>
          Applen kehittämä mobiilikäyttöjärjestelmä iOS on suomen toiseksi
          käytetyin käyttöjärjestelmä ja sillä on 12.7 prosentin markkinaosuus
          maailmanlaajuisesti. Pohjois-Amerikassa ja Länsi-Euroopassa se tosin
          on käytetyin mobiilikäyttöjärjestelmä. iOS-alusta on käytössä Applen
          iPhone, iPod Touch, iPad ja Apple TV-laitteissa. iOS käyttöjärjestelmä
          on suunniteltu kosketusnäytölle ja sen toimintoja ohjataan käyttäjän
          näytön pinnalla tekemillä liikkeille, esimerkiksi kosketus-, nipistys-
          ja pyyhkäisy menetelmillä.
        </Text>

        <Text>Millainen on keskiverto iOS-käyttäjä?</Text>
        <BasicList>
          <li>
            iOS käyttäjä yleensä asuu Pohjois-Amerikassa tai Länsi-Euroopassa
          </li>
          <li>Yleensä työskentelee yrittäjänä tai luovalla-alalla</li>
          <li>Käyttää enemmän rahaa internetissä ja mobiilisovelluksissa</li>
          <li>
            Käyttää yleensä eniten sovelluksia seuraavissa kategorioissa:
            Business, koulutus ja elämäntyyli
          </li>
          <li>Tekee todennäköisesti ostoksia applikaation sisällä</li>
        </BasicList>
        <Text>iOS-sovelluskehityksen hyvät puolet:</Text>
        <BasicList>
          <li>Vahva asema Yhdysvalloissa ja Läntisessä Euroopassa.</li>
          <li>Tuottaa keskivertoisesti enemmän</li>
        </BasicList>
        <Text>iOS-sovelluskehityksen huonot puolet:</Text>
        <BasicList>
          <li>iOS-käyttöjärjestelmällä on puolet vähemmän käyttäjiä</li>
          <li>Vaikeampi julkaista applikaatioita</li>
          <li>
            Tuottaa vähemmän liikevaihtoa sovelluksen sisäisissä mainoksissa
          </li>
        </BasicList>
        <BlogTitle tag="h3">Google Play</BlogTitle>
        <Text>
          Android-käyttöjärjestelmän on kehittänyt Google ja se pohjautuu
          Linux-käyttöjärjestelmään. Android-käyttöjärjestelmä on suomen yleisin
          mobiililaitteen käyttöjärjestelmä ja sillä on 87% markkinaosuus koko
          maailman mittakaavassa. Maailmanlaajuisestikkaan se ei häviä iOS
          käyttöjärjestelmälle kuin Pohjois-Amerikassa ja Länsi-Euroopassa.
          Android toimii mobiililaitteiden lisäksi esimerkiksi autoissa,
          televisioissa ja älykelloissa. Android sovellusta kirjoitetaan
          Java-kielellä.
        </Text>

        <Text>Millainen on keskiverto Android-käyttäjä?</Text>

        <BasicList>
          <li>Asuu Etelä-Amerikassa, Aasiassa, Keski- tai Itä-Euroopassa</li>
          <li>Työskentelee yleensä tekniikan parissa</li>
          <li>Arvostaa hinta-laatusuhdetta</li>
          <li>
            Käyttää yleensä eniten sovelluksia seuraavissa kategorioissa:
            Työkalut, viihde ja kommunikaatio
          </li>
          <li>On lojaalimpi ohjelmiston sisäisille mainoksille</li>
        </BasicList>
        <Text>Android-sovelluskehityksen hyvät puolet:</Text>
        <BasicList>
          <li>Suuremmat markkinat</li>
          <li>Enemmän applikaatioita</li>
          <li>Enemmän liikevaihtoa sovelluksen sisäisissä mainoksissa</li>
        </BasicList>
        <Text>Android-sovelluskehityksen huonot puolet:</Text>
        <BasicList>
          <li>Android-sovellukset saavat vähemmän liikevaihtoa</li>
          <li>Enemmän potentiaalisia bugeja</li>
        </BasicList>

        <BlogTitle tag="h3">Käyttäjäpalaute</BlogTitle>
        <Text>
          Sen jälkeen kun mobiilisovellus on julkaistu ja se on ollut hetken
          aikaa muiden ladattavissa ja käytettävissä niin sovellus alkaa saamaan
          todennäköisesti käyttäjäpalautetta. Vaikka käyttäjäpalaute olisikin
          negatiivista – ei siitä tule suuttua. Negatiivinen käyttäjäpalaute
          tulee osata käsitellä ja siitä saatua dataa pitää osata jalostaa, eli
          negatiivinen on yleensä itseasiassa sinulle hyödyllisempää, sillä sen
          avulla pystyt kehittämään sovellusta ja korjaamaan bugeja, jos niitä
          on.
        </Text>
        <Text>
          Käyttäjäpalaute voi myös olla positiivista ja sinun kannattaa yrittää
          vastata kaikkeen palautteeseen aina kun on mahdollista, sillä
          käyttäjät arvostavat sitä, että heidät otetaan huomioon olivat he
          sitten antaneet negatiivista tai positiivista palautetta.
        </Text>
        <Text>
          Dataa käyttäjäkokemuksesta voidaan kerätä kauppa-alustoilta mihin
          sovellus on julkaistu (Play- tai App-kauppa) tai itse sovellukseen on
          mahdollista ohjelmoida käyttäjäkokemusta koskeva kysely tai arvostelu.
          Jokainen palaute on tärkeää ja se auttaa sinua ymmärtämään
          sovelluksesi käyttäjiä paremmin.
        </Text>
        <BlogTitle>Markkinointi</BlogTitle>
        <Text>
          Olemme pian kirjoittamassa blogikirjoituksen mobiiliapplikaatioiden
          markkinoinnista, joten emme perehdy siihen tässä niin
          yksityiskohtaisesti.
        </Text>
        <BlogTitle tag="h3">Markkinointi Google Play kaupassa</BlogTitle>
        <Text>
          Sinun on mahdollista markkinoida sovellustasi kuluttamatta rahaa tai
          maksamalla mainoksista. Jos et halua kuluttaa rahaa markkinointiin,
          hyviä vaihtoehtoja on sosiaalisessa mediassa mainostaminen, postaile
          kuvia/videoita sovelluksestasi ysäväpiirillesi ja pyydä heitä jakamaan
          postauksesi. Riippuen siitä, minkälainen sovellus on kyseessä niin
          kannattaa miettiä kanavia missä mainostaa, esimerkiksi LinkedIn:ssä ei
          välttämättä kannata mainostaa pelisovellusta.
        </Text>
        <Text>
          Jos haluat kuluttaa rahaa mainostamiseen, niin Google Adwords on oiva
          väline tähän – sen avulla voit esimerkiksi saada mainokset näkymään
          pelkästään mobiilissa.
        </Text>

        <BlogTitle tag="h3">
          Universaali sovelluskampanja – Google Adwords
        </BlogTitle>
        <Text>
          Google Adwordsissa pystyt myös valitsemaan niin sanotun universaali
          sovelluskampanjan. Se sopii sovellusten mainostamiseen ja sen avulla
          voit tavoittaa asiakkaita esimerkiksi Googlen haussa, Google Playssa,
          YouTubessa ja Google Display-verkossa. Sinun tulee lisätä vain vähän
          tekstiä, hintatarjous ja sisältö, sekä kertoa mainostesi kielet ja
          sijainnit – kaikki muu tullaan optimoimaan jotta käyttäjät löytävät
          sinut.
        </Text>
        <Text>
          Universaaleissa sovelluskampanjoissa ei suunnitella yksittäisiä
          mainoksia vaan sen sijaan siinä käytetään mainostekstejä sekä
          sovelluskaupassa julkaisemaasi sisältöä, joiden avulla niistä
          suunnitellaan useita eri muodoissa ja verkostoissa käytettäviä
          mainoksia.
        </Text>
        <Text>
          Google yhdistää mainoksesi hakutermeihin, jotka liittyvät olennaisesti
          sovellukseesi. Adwords luo sinulle avainsanoja esimerkiksi käyttämällä
          Google Play:n hakutermejä joiden kautta käyttäjät ovat päätyneet
          sovellukseesi.
        </Text>

        <BlogTitle tag="h3">Markkinointi App store kaupassa</BlogTitle>
        <Text>
          App Storen sovelluksen hinnoista 30% menee Applelle ja 70% menee
          sovelluksen kehittäjälle.
        </Text>

        <BlogTitle tag="h4">
          Perusperiaate App Storessa mainostamisessa
        </BlogTitle>
        <Text>
          Valitset avainsanat joko itse tai käytät Appstoren ehdottamia. Voit
          valita näytetäänkö mainoksesi esimerkiksi uusille asiakkaille,
          olemassa oleville asiakkaille, henkilöille jotka ovat jo aiemmin
          ladanneet jonkun sovelluksesi vai kaikille. Pystyt myös määrittämään
          kohdeyleisön iän, sukupuolen ja asettamaan mainokset näytettäviksi
          ainoastaan tiettyyn maantieteelliseen alueeseen.
        </Text>

        <Text>
          Voit valita näytetäänkö mainoksesi iPhonessa, iPadissa vai
          kummassakin. Voit myös valita mainosten näyttämisaikataulun alkamaan
          tietystä päivästä tai tiettyyn kellonaikaan.
        </Text>

        <Text>
          App Storessa ei ole minimibudjettia, joten voit itse valita kuinka
          paljon haluat käyttää mainostamiseen.
        </Text>

        <BlogTitle>Lopuksi</BlogTitle>
        <Text>
          Blogikirjoituksen alussa käsittelimme kohderyhmän kartoitusta ja
          kohdehenkilöä. Tässä kohtaa kohdehenkilön rooli korostuu
          huomattavasti. Jos emme tiedä edes suurinpiirtein millainen on
          kohdehenkilö kenelle mainostamme mobiiliapplikaatiota, voimme menettää
          huomattavia summia rahaa jos maksamme mainoksista. Mutta jos taas
          olemme perillä siitä, millainen kohdehenkilö/kohdeyleisö on, niin
          maksetuilla mainoksilla voimme saavuttaa laajan huomion ja saada
          konversioita aikaiseksi.
        </Text>
      </Container>
    </section>
  </Layout>
)

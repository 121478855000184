import React from "react"

interface Props {
  children: string | JSX.Element | JSX.Element[]
}

export default ({ children }: Props): JSX.Element => (
  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
    {children}
  </p>
)

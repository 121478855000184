import React from "react"
import BaseTextElement, { BaseTextElementProps } from "./BaseTextElement"
import { TypoTag } from "./TypoTag"

interface Props extends BaseTextElementProps {
  tag: TypoTag
  children: string | JSX.Element
}

export default ({
  tag,
  children,
  additionalClassNames,
}: Props): JSX.Element => (
  <BaseTextElement
    baseClassNames="text-lg text-indigo-600 font-semibold tracking-wide uppercase"
    additionalClassNames={additionalClassNames}
    tag={tag}
  >
    {children}
  </BaseTextElement>
)

import React from "react"
import Title from "./Title"
import { TypoTag } from "./TypoTag"

interface Props {
  children: string
  tag?: TypoTag
}

export default ({ children, tag }: Props): JSX.Element => (
  <Title additionalClassNames="mt-10 mb-4" tag={tag || "h2"}>
    {children}
  </Title>
)
